<template>
    <div class="display-block">
        <h3>Vendor</h3>
        <v-card elevation="4">
            <v-card-text>
                <v-layout wrap>
                    <v-flex sm6>
                        <row-item label="Business Name  " :value="vendor.businessName"></row-item>
                    </v-flex>
                    <v-flex sm6>
                        <row-item label="Store" :value="generateUrl(vendor.slug)"></row-item>
                    </v-flex>
                    <v-flex sm6>
                        <row-item label="Mobile Number  " :value="vendor.mobileNo"></row-item>
                    </v-flex>
                    <v-flex sm6>
                        <row-item label="Whatsapp Number  " :value="vendor.whatsappNo"></row-item>
                    </v-flex>
                    <v-flex sm6>
                        <row-item label="Email  " :value="vendor.email"></row-item>
                    </v-flex>
                    <v-flex v-if="vendor.address" sm4>
                        <row-item label="Address  " :value="vendor.address"></row-item>
                    </v-flex>
                    <v-flex v-if="vendor.city" sm4 text-center>
                        <row-item label="City  " :value="vendor.city"></row-item>
                    </v-flex>
                    <v-flex v-if="vendor.area" sm4>
                        <row-item label="Area  " :value="vendor.area"></row-item>
                    </v-flex>
                    <v-flex sm4>
                        <row-item label="Allowed Modules">
                            <modules-list :allowedModules="vendor.licensingDetails.allowedModules"></modules-list>
                        </row-item>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <v-flex text-right>
            <v-btn @click="$router.go(-1)" class="ma-2" :style="theme">Back</v-btn>
        </v-flex>
    </div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import RowItem from '@/components/RowItem'
import ModulesList from '../../../components/ModulesList.vue'
export default {
    components: {
        RowItem,
        ModulesList
    },
    data() {
        return {
            id: 0,
            items: [],
            vendor: {
                businessName: "",
                mobileNo: "",
                email: "",
                address: "",
                city: "",
                area: ""
            }
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.vendor = await this.getItem(appConstants.VENDORS_API + "/" + this.id)
        }
    }
}
</script>
